<template>
  <div class="rules">
    <el-image fit="cover" v-if="ruleInfo.topImg" :src="ruleInfo.topImg"></el-image>
    <div class="text">
      {{ ruleInfo.context }}
    </div>
    <div class="download">
      <span class="download-text" v-for="item in ruleInfo.pdfInfo || []" :key="item.downloadUrl" @click="download(item)">
        <span class="pdf">
          PDF
        </span>
        {{ item.title }}
      </span>
    </div>
    <el-image v-if="ruleInfo.detailImg" fit="cover" :src="ruleInfo.detailImg"></el-image>
  </div>
</template>

<script>
import ApiService from '@/api/apiService';
import API from '@/api';
import { LanguageEnum, Language } from '@/utils/constants';
export default {
  name: 'rules',
  computed: {
    bannerImg() {
      return this.$store.state.isChinese ? require('@/static/images/rules.jpg') : require('@/static/images/en-rules.jpg');
    }
  },
  data() {
    return {
      ruleInfo: {}
    };
  },
  components: {},
  watch: {
    '$store.state.isChinese'() {
      this.getRule();
    }
  },
  mounted() {
    this.getRule();
  },
  methods: {
    async getRule() {
      const language = LanguageEnum[this.$store.state.isChinese ? Language.CN : Language.EN];
      const result = await ApiService.get(API.rule + language, {
        language: LanguageEnum[this.$store.state.isChinese ? Language.CN : Language.EN]
      });
      this.ruleInfo = result.data;
    },
    download(data) {
      const a = document.createElement('a');
      a.href = data.downloadUrl;
      a.download = data.title + '.pdf';
      document.body.append(a);
      a.click();
    }
  }
};
</script>

<style scoped lang="less">
.rules {
  width: 72vw;
  .el-image {
    border-radius: 10px;
  }
  .text {
    margin: 2.1vw 0;
    text-align: justify;
  }
  .download {
    margin: 2.1vw 0;
    text-align: right;
    .download-text {
      margin-right: 0.55vw;
      padding: 0.278vw;
      border-radius: 3px;
      border: 1px solid #9f0518;
      font-size: 1.1vw;
      font-weight: 300;
      color: #9f0518;
      line-height: 1.53vw;
      .pdf {
        padding: 0.14vw 0.28vw;
        margin-right: 0.42vw;
        background: #9f0518;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}
</style>
